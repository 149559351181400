// ----------------------------------------------------------------------




export const account = {
  displayName: 'L&B Admin',
  email: 'demo@minimals.cc',
  photoURL: '/assets/images/avatars/avatar_25.jpg',
};
