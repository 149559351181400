import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Add Roles',
    path: '/all-roles-list',
    icon: icon('ic_add_user'),
  },
  {
    title: 'Verified Students',
    path: '/veri-stu',
    icon: icon('ic_lock'),
  },
  {
    title: 'Students',
    path: '/user',
    icon: icon('ic_user'),
  },
  { 
    title: 'Best Offers Data',
    path: '/best-offers',
    icon: icon('ic_cart'),
  },
  // { 
  //   title: 'Best Offers Data',
  //   path: '/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'Brochures Data',
  //   path: '/blog',
  //   icon: icon('ic_blog'),
  // },
  {
    title: 'Brochures Data',
    path: '/bros-data',
    icon: icon('ic_blog'),
  },
  
  {
    title: 'LNB Id Seq.',
    path: '/add-id-seq',
    icon: icon('ic_sequence'),
  },
  {
    title: 'Import Data',
    path: '/import-data',
    icon: icon('ic_import'),
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
