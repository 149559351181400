// /* eslint-disable perfectionist/sort-imports */
import './global.css';

import { useScrollToTop } from './hooks/use-scroll-to-top';

import FinalRoutingSection from './routes/FinalRoutingSection';
import ThemeProvider from './theme';
import {Provider} from 'react-redux'
import store from './Store/Store';


// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  store.subscribe(()=>{
    localStorage.setItem('reduxStore' , JSON.stringify(store.getState()))
  })

  return (
    
    <Provider  store={store} >

    <ThemeProvider>
      <FinalRoutingSection />
    </ThemeProvider>
    </Provider>
  );
}
