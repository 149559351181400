import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import { useSelector } from 'react-redux';

export const IndexPage = lazy(() => import('../pages/app'));
export const BlogPage = lazy(() => import('../pages/blog'));
export const GetBestOffers  =  lazy(()=> import('../pages/Get-Best-Offers'))
export const VerifiedStu  =  lazy(()=> import('../pages/Verified-Students'))
export const AddStudentPage  =  lazy(()=> import('../pages/Add-Student'))
export const AddIdSequencePage  =  lazy(()=> import('../pages/Add-ID-Sequence'))
export const AddRolePage  =  lazy(()=> import('../pages/Add-Roles'))
export const EditRolePage  =  lazy(()=> import('../pages/EditRole'))
export const ListRolePage  =  lazy(()=> import('../pages/RolesTable'))
export const BulkProcessPage  =  lazy(()=> import('../pages/BulkProcess'))
export const EditStudentPage  =  lazy(()=> import('../pages/Edit-Student'))
export const DownloadBros  =  lazy(()=> import('../pages/Brochures-data'))
export const UserPage = lazy(() => import('../pages/user'));
export const LoginPage = lazy(() => import('../pages/login'));
export const ProductsPage = lazy(() => import('../pages/products'));
export const Page404 = lazy(() => import('../pages/page-not-found'));
export const ImportRazorPage = lazy(()=> import('../pages/Import-Razor'))



// ----------------------------------------------------------------------

              // acc_add_users: acc_add_users,
              // acc_best_offers_data: acc_add_users,
              // acc_brochures_data: acc_brochures_data,
              // acc_dashboard:acc_dashboard,
              // acc_import_data: acc_import_data,
              // acc_lnb_seq: acc_lnb_seq,
              // acc_students: acc_students,
              // acc_verified: acc_verified

export default function Router() {

  const UserData   = useSelector((state)=> state.LoginReducer && state.LoginReducer.data  ? state.LoginReducer.data : {})

  const persmissonData  =  {
              acc_add_users: UserData.acc_add_users,
              acc_best_offers_data: UserData.acc_add_users,
              acc_brochures_data: UserData.acc_brochures_data,
              acc_dashboard:UserData.acc_dashboard,
              acc_import_data: UserData.acc_import_data,
              acc_lnb_seq: UserData.acc_lnb_seq,
              acc_students: UserData.acc_students,
              acc_verified: UserData.acc_verified
  }

  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'user', element:  persmissonData.acc_students ?  <UserPage /> : <Page404 /> },
        // { path: 'products', element: <ProductsPage /> },
        { path: 'best-offers', element: persmissonData.acc_best_offers_data ? <GetBestOffers />  : <Page404 />},
        // { path: 'blog', element: <BlogPage /> },
        { path: 'bros-data', element: persmissonData.acc_brochures_data ? <DownloadBros /> : <Page404 /> },
        { path: 'veri-stu', element: persmissonData.acc_verified ? <VerifiedStu /> : <Page404 /> },
        { path: 'bulk-process', element: persmissonData.acc_verified ? <BulkProcessPage /> : <Page404 /> },
        { path: 'add-student', element:  persmissonData .acc_students ? <AddStudentPage /> : <Page404 /> },
        { path: 'add-id-seq', element: persmissonData.acc_lnb_seq ? <AddIdSequencePage /> : <Page404 /> },
        { path: 'add-roles', element: persmissonData.acc_add_users  ?<AddRolePage/> : <Page404 /> },
        { path: 'all-roles-list', element: persmissonData.acc_add_users ? <ListRolePage/> : <Page404 /> },
        { path: 'import-data', element: persmissonData.acc_import_data ? <ImportRazorPage /> : <Page404 /> },
        { path: 'edit-student/:id', element: persmissonData.acc_students ? <EditStudentPage /> : <Page404 /> },
        { path: 'edit-role/:id', element: persmissonData.acc_add_users? <EditRolePage /> : <Page404 /> },
      ],
    },
    // {
    //   path: 'login',  
    //   element: <LoginPage />,
    // },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
